<template>
    <div class="template-page grey-bg white-step person">
        <basic-page :pagedata="this.pageData" :bannerimg="introimg">




            <div class="person-header step-big">
                <div class="profile-pic ">
                    <div class="picture-wrapper">
                        <img :src="this.profilePic" alt="profile picture">
                    </div>
                    <div class="roles">
                        <p class="person-role" v-for="(role, index) in personData.field_person_position" :key="'role'+index">
                            {{ role.attributes.name }}
                        </p>
                    </div>
                </div>
                <div class="content angle yellow-angle">
                    <div class="info">
                        <div class="contacts">
                            <div class="email" v-if="this.personData.attributes">
                                {{ this.personData.attributes.field_email[0] }}
                            </div>
                            <span v-if="this.personData.attributes && this.personData.attributes.field_phone">|</span>
                            <div class="phone" v-if="this.personData.attributes && this.personData.attributes.field_phone">{{this.personData.attributes.field_phone }}</div>
                            <span v-if="this.personData.attributes && this.personData.attributes.field_person_office">|</span>
                            <div class="office" v-if="this.personData.attributes && this.personData.attributes.field_person_office!=null">
                                Office: {{ this.personData.attributes.field_person_office }}
                            </div>
                        </div>
                        <div class="social" v-if="this.personData.attributes">
                            <a  v-if="this.personData.attributes.field_person_website.length>0" 
                                :href="this.personData.attributes.field_person_website[0].uri"
                                target="_blank">
                                <img src="../../assets/globe.svg" alt="website"></a>

                            <a v-if="this.personData.attributes.field_social_twitter" 
                                target="_blank" 
                                :href="this.personData.attributes.field_social_twitter.uri">
                                <img src="../../assets/twitter.png" alt="social"></a>
                            <a v-if="this.personData.attributes.field_social_linkedin" 
                                target="_blank" 
                                :href="this.personData.attributes.field_social_linkedin.uri">
                                <img src="../../assets/linkedin.png" alt="social"></a>
                            <a v-if="this.personData.attributes.field_social_instagram" 
                                target="_blank" 
                                :href="this.personData.attributes.field_social_instagram.uri">
                                <img src="../../assets/instagram.png" alt="social"></a>
                            <a v-if="this.personData.attributes.field_social_facebook" 
                                target="_blank" 
                                :href="this.personData.attributes.field_social_facebook.uri">
                                <img src="../../assets/facebook.png" alt="social"></a>
                        </div>
                    </div>
                    <div class="bio" v-if="this.personData.attributes && this.personData.attributes.field_person_bio" 
                        v-html="this.personData.attributes.field_person_bio.processed">
                    </div>
                </div>
            </div>
            <h2 v-if="this.coursesData.length>0" class="title-section">
                Active Courses
            </h2>
            
            <div class="active-courses">
                <div v-for="(mod, index) in coursesData" :key="'course'+index">
                    <module-slim :data="mod"></module-slim>
                </div>
            </div>
            
            <div class="research-units-container" v-if="this.ruData.length>0">
                <h2 v-if="this.ruData.length>0" class="title-section">
                    Research Units
                </h2>
                <div class="research-units">
                    <div v-for="(ru, index) in this.ruData" :key="'ru'+index">
                        <single-research :ppldata="allPeople" :data="ru"></single-research>
                    </div>
                </div>
            </div>
            
            <h2 v-if="publicationData.length>0" class="title-section" style="margin-top: 4rem;">
                Publications
            </h2>
            
            <PubsList v-if="publicationData.length>0" :worksdata="this.publicationData" />


            <ThesisList :thesisData="this.thesisData"  />



        </basic-page>
    </div>
</template>

<script>
import ThesisList from '../../components/ThesisList.vue'
import { fetchNodes, fetchSingleNode } from '../../libs/drupalClient';
import BasicPage from '../BasicPage.vue'
import SingleResearch from '../../components/SingleResearch.vue'
import ModuleSlim from '../../components/ModuleSlim.vue';
import PubsList from '../../components/PubsList.vue';

export default {
  components: { BasicPage, SingleResearch,  ModuleSlim, PubsList, ThesisList },
    name: 'person',
    data: () => {
        return {
            personData: {},
            ruData: [],
            pageData: {
                title: '',
                subtitle: ''
            },
            introimgbannerID: '378ee55b-97c5-47d4-9fcb-ec5bdaee512a',
            introimg: '',
            allPeople: {},
            publicationData: [],
            coursesData: {},
            islecturer: false,
            thesisData: [],
        }
    },
    props: {
    },
    methods: {
        async fetchPerson() {
            if(this.personNID) {
                await fetchSingleNode('page', this.introimgbannerID, { 
                    include: ['field_banner_top'] }
                ).then(res => {
                    if(res[0].field_banner_top) this.introimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
                })

                await fetchNodes('person', {
                    include: ['field_person_photo', 'field_person_position']
                }).then(res => {
                    this.allPeople = res
                    this.personData = res.find(p => p.attributes.drupal_internal__nid == this.personNID)
                    this.personData.field_person_position.forEach(p => { 
                        if(p.attributes.name=='Lecturer') this.islecturer = true
                    })
                    this.pageData.title = this.personData.attributes.field_first_name + ' ' + this.personData.attributes.field_last_name
                    //console.log(this.personData)

                    fetch(process.env.VUE_APP_ENDPOINT + '/publications/' + this.personData.attributes.drupal_internal__nid)
                    .then(res => res.json())
                    .then(json => {
                        this.publicationData = json.data.publications.works
                        //console.log(this.publicationData)
                    })
                })
            }
        },
        fetchRUs() {
            fetchNodes('research_unit', {
                include: ['field_ru_leader_ref', 'field_ru_member_ref']
            }).then(res => {
                this.ruData = res.filter(ru => ru.field_ru_leader_ref.id == this.personData.id)
                res.forEach(ru => {
                    ru.field_ru_member_ref.forEach(
                        m => {
                            if(m.id == this.personData.id)
                                this.ruData.push(ru)
                        }
                    )
                })
            })
        },
        fecthCourses() {
            fetchNodes('module', {
                include: ['field_module_lecturer_ref']
            })
            .then(res => {
                this.coursesData = res.filter(r => {return r.field_module_lecturer_ref[0].id == this.personData.id})
            })
        }
    },
    computed: {
        /* introimg() {
            return process.env.VUE_APP_ENDPOINT + '/sites/default/files/bg.jpg'
        }, */
        personNID() {
            //console.log(this.$route.params)
            return this.$route.params.nid//.split('-').slice('-1')[0];
        },
        profilePic() {
            if(this.personData.field_person_photo) 
                return (process.env.VUE_APP_ENDPOINT + this.personData.field_person_photo.attributes.uri.url)
            else 
                return '/placeholder-people.svg';
        },
        /*
        personalWebsite() {
            if(this.personData && this.personData.attributes) {
                if(this.personData.attributes.field_person_website[0]) {
                    return this.personData.attributes.field_person_website[0]
                } else
                    return ''
            } else 
                return ''
        },
        personalEmail() {
            if(this.personData && this.personData.attributes) {
                if(this.personData.attributes.field_email[0]) {
                    return this.personData.attributes.field_email[0]
                } else 
                    return "place.holder@email.com" 
            } else 
                return "place.holder@email.com" 
        },
        personalPhone() {
            if(this.personData && this.personData.attributes) {
                if(this.personData.attributes.field_phone) {
                    return this.personData.attributes.field_phone
                } else
                    return "+AA BBB CCC DDDD"
            } else
                return "+AA BBB CCC DDDD"
        },
        personalBio() {
            if(this.personData && this.personData.attributes) {
                if(this.personData.attributes.field_person_bio) {
                    return this.personData.attributes.field_person_bio.processed
                } else
                    return 'Biography'
            } else
                return "Biography" 
        }
        */
    },
    mounted() {
        this.fetchPerson()



        let data;
        fetchNodes('thesis', {
                include: ['field_students','field_internal_supervisor','field_funding_stream']
            }).then(res => {
                data = res
            }).then(() => {
                async function fetchStudentPhotos(data) {
                    for (let i = 0; i < data.length; i++) {

                        const el = data[i];
                        
                        try {
                            const response = await fetch(el.field_students.relationships.field_person_photo.links.related.href);
                            const dataPhoto = await response.json();
                        
                        if (dataPhoto.data) {
                            Object.assign(data[i].field_students, dataPhoto.data.attributes.uri);
                        }
                        } catch (error) {
                            console.error('Fetch error:', error);
                        }

                        if (i + 1 === data.length) {
                            let d = []
                            d = data.filter(d=> d.field_students.attributes.drupal_internal__nid == this.$route.params.nid)
                            this.thesisData = d
                        }
                    }
                }
                fetchStudentPhotos.call(this, data);
            })


            
    },
    watch: {
        personData() {
            this.fetchRUs()
            if(this.islecturer) this.fecthCourses()
        },
        isLecturer() {
            if(this.islecturer) this.fecthCourses()
        },
        personNID() {
            this.fetchPerson()
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

.angle::after {
    top: 30px;
}

.person-header.step-big {
    border-top: 10px solid $mainColor;
}




.research-units-container  {
    background-color: #fff;
    position: relative;
    z-index: 1;
    &:before{
        content: "";
        width: 150vw;
        height: 100%;
        position: absolute;
        top: 0%;
        left: -30%;
        background-color: rgb(255, 255, 255);
        Z-INDEX: 0;
        padding-bottom: 80px;
    }
    .research-units {
        position: relative;
        z-index: 1;
    }
    
}

h2 {
    color: $secondaryColor;
    margin-bottom: 2rem;
    @media (max-width: 576px) {
        padding-left: 2rem;
    }
}

.person-header {
    background-color: #fff;
.person-role {
    line-height: 1.5em;
    padding: .3em .7em;
    margin: 2.5px 0;
    background: $mainColor;
    color: $secondaryColor;
    font-weight: 700;
    font-size: .9em;
    text-transform: uppercase;
    width: fit-content;
}

      .info {
        display: flex; display: -webkit-flex;
        justify-content: space-between;
        font-size: 1.2em;

        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 2px #eee solid;

        .contacts {
          .email {
            color: darken($mainColor, 10%);
            font-weight: 500;
            //margin-bottom: 7px;
            margin-right: 20px;
            //margin-left: 20px;
          }

          .phone {
            color: $secondaryColor;
            font-weight: 500;
            //margin-bottom: 7px;
            margin-right: 20px;
            margin-left: 20px;
          }
          .office{
              margin-left: 20px;
          }
        }

        .social {
          display: flex; display: -webkit-flex;
          flex-direction: row;

          a {
            display: block;
            width: 30px;
            height: 30px;
            margin-left: 10px;
            

            img {
              width: 100%;
              display: block;
              object-fit: contain;
              &:hover {
                opacity: .5;
            }
            }
          }
        }
      }


      .bio {
        line-height: 1.7em;
      }
      }

@media (max-width:1500px) {
    .person .content.angle::after{
        right: 2px;
    }
    .person-header .content .info .contacts {
        font-size: 1rem;
    }
}
  
@media (max-width:1200px) {
        .person-header .content .info .contacts {
        display: flex;
    font-size: 1rem;
    flex-direction: column;
    align-items: flex-start;
     span {
         display: none;
     }
     .email,
     .office,
     .phone {
         margin: 2px 0;
     }
    }
}
 
@media (max-width:768px) {
    .person .content.angle::after{
        right: 20px;
    }
}
@media (max-width:576px) {
    .person .content.angle::after{
        right: 14px;
    }
    h2.title-section {
        padding-left: 15px;
    }
}
</style>