<template>
    <div class="module-wrapper angle yellow-angle">
        <div class="title-tile">
          <div >
              <h3 class="module-type">{{ 
                this.data.attributes.field_module_term + ' - ' + 
                this.data.attributes.field_module_type + ' type' 
              }}</h3>
              <h2 class="module-title">{{ this.data.attributes.title }}</h2>
          </div>
        </div>
        <div class="info">
          <h3 class="date-label">DATE</h3>
          <div class="date" v-if="this.data.attributes.field_module_date_from">
              {{newDateFrom + ' - ' + newDateTo}}
          </div>
        </div>
    </div>
</template>

<script>
import { reWriteDate } from '../libs/utils'

export default {
  name: 'module-slim',
  data: () => {
    return {
    }
  },
  props: {
    data: Object
  },
  computed:{
    newDateFrom() {
      if(this.data.attributes.field_module_date_from)
        return reWriteDate(this.data.attributes.field_module_date_from)
      else 
        return ''
    },
    newDateTo() {
      if(this.data.attributes.field_module_date_to)
        return reWriteDate(this.data.attributes.field_module_date_from)
      else 
        return ''
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/colors.scss';

.module-wrapper {
    display: flex; display: -webkit-flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
    padding: 30px 40px;

    .title-tile {
      width: 60%;
    }

    .info {
      width: 40%;
    }

    .module-type {
      color: #112342;
      font-size: 1rem;
      font-weight: 600;
      padding: 5px 0rem;
    }

    .module-title {
      color: #112342;
      font-size: 1.5625rem;
      font-weight: 400;
      padding: 10px 0rem;
      margin-top: 0px;
    }

    .date-label {
      color: #112342;
      font-size: 1rem;
      padding: 5px 0rem;
    }

    .date {
      color: #112342;
      font-size: 1.35rem;
      font-weight: 600;
      padding: 5px 0rem;
    }
}

@media (max-width: 992px) {
  .module-wrapper {
    flex-direction: column;
    align-items: unset;

    .title-tile {
      width: 100%;
    }

    .info {
      width: 100%;
    }
  }
}

@media (max-width: 576px) {
  .module-wrapper {
      padding: 30px 0px;
  }
}

</style>