<template>
<div>
    <div class="filters-bar">
        <h2>Filter by</h2>
        <div class="filters-row">
            <div class="ricerca-testo">
                <label for="searchtitle">Title</label>
                <input v-model="searchtitle" name="searchtitle" placeholder="Search by title...">
            </div>
            <div class="ricerca-testo">
                <label for="searchauthor">Author</label>
                <input v-model="searchauthor" name="searchauthor" placeholder="Search by Surname...">
            </div>
            <div class="ricerca-testo">
                <label for="searchyear">Year</label>
                <select name="searchyear" id="" @change="filterbyyear()">
                    <option value="all">All years</option>
                    <option v-for="y in this.allyears" :key="'year_'+y" :value="y">{{ y }}</option>
                </select>
            </div>
            <div class="ricerca-testo">
                <label for="searchtype">Type</label>
                <select name="searchtype" id="" @change="filterbytype()">
                    <option value="all">All types</option>
                    <option value="journal-article">Journal article</option>
                    <option value="book">Book</option>
                    <option value="book-chapter">Book chapter</option>
                    <option value="report">Report</option>
                </select>
            </div>
        </div>
        <div class="sorting">
            <div>Sort by</div>
            <input type="radio" name="sorting" id="sortdate" value="date" checked="checked" @click="radioclick()">
            <label for="sortdate">Date</label>
            
            <input type="radio" name="sorting" id="sorttitle" value="title" @click="radioclick()">
            <label for="sorttitle">Title</label>
        </div>
    </div>


    <div class="publication-list" v-if="this.filteredWorks.length">
        <article v-for="(w, ind) in loadedWorks" :key="'work_'+ind" 
            :class="['angle publication-card', pubclass(w) ]">
            <div class="preview"></div>
            <div class="pub-year">Year of publication {{ w.field_publication_year }}</div>
            <h3 class="pub-title title">{{ w.title }}</h3>
            <div class="pub-authors">
                Authors:
                <div class="names"> {{ authnames(w) }} </div>
                <button class="showauths" v-if="authnames(w).length > 250" @click="openaccordion()">
                    <span>Show all authors</span>
                    <span>Collapse</span>
                </button>
            </div>
            <div class="pub-link" v-if="w.field_single_url">
                <a :href="w.field_single_url" target="_blank" class="cta-link"> <span>Learn more</span> </a>
            </div>
        </article>
    </div>


    <div class="loadmore" v-if="this.filteredWorks.length > this.loaded + 10">
        <button ref="buttonLoadMore" class="cta" @click="loadMore()"><span>Load More</span></button>
    </div>

</div>
</template>

<script>
export default {
    name: 'pubs-list',
    props: {
        worksdata: Array
    },
    data: () => {
        return {
            loaded: 10,
            searchtitle: '',
            searchauthor: '',
            sorting: 'date',
            yearfilter: 'all',
            typefilter: 'all'
        }
    },
    methods: {
        radioclick() { 
            this.sorting = event.target.value
        },
        printAuthors(work) {
            if(work.field_publication_authors) {
                var auths = work.field_publication_authors.split(" | ")
                if(auths.length > 6) {
                    auths = auths.slice(0, 6)
                    auths.push('... and others')
                }
                return auths
            }
            else return []
        },
        authnames(work) {
            if(work.field_publication_authors) {
                var auths = work.field_publication_authors.split(" | ")
                return auths.join(', ')
            } 
            else return ''
        },
        loadMore() { 
            this.loaded += 10 
        },
        filterbyyear() {
            this.yearfilter = event.target.value
        },
        filterbytype() {
            this.typefilter = event.target.value
        },
        pubclass(work) {
            return work.field_publication_type
        },
        openaccordion() {
            event.target.closest('.pub-authors').classList.toggle('open')
        }
    },
    computed: {
        loadedWorks() {
            return this.filteredWorks.slice(0, this.loaded)
        },
        filteredWorks() {
            var b = []

            b = this.worksdata.filter(w => !Array.isArray(w))

            /* FILTER BY YEAR */
            if(this.yearfilter != "all") {
                b = b.filter(w => w.field_publication_year == this.yearfilter)
            }

            /* FILTER BY TYPE */
            if(this.typefilter != "all") {
                b = b.filter(w => w.field_publication_type == this.typefilter)
            }

            /* SEARCH BY TITLE */
            if(this.searchtitle) {
                b = b.filter(w => w.title.toLowerCase().includes(this.searchtitle.toLowerCase()))
            }

            /* SEARCH BY AUTHOR */
            if(this.searchauthor) {
                b = b.filter(w => w.field_publication_authors.toLowerCase().includes(this.searchauthor.toLowerCase()))
            }

            /* SORTING */
            if(this.sorting == 'date')
                b.sort((a, b) => {
                    return a.field_publication_year > b.field_publication_year ? -1 : 1
                })
            else if(this.sorting == 'title')
                b.sort((a, b) => {
                    return a.title > b.title ? 1 : -1
                })

            return b
        },
        allyears() {
            var yy = []
            this.worksdata.forEach(y => {
                if(!yy.includes(y.field_publication_year) && y.field_publication_year!="")
                    yy.push(y.field_publication_year)
            })
            return yy
        }
    }  
}
</script>

<style lang="scss" scoped>

</style>